import OpenAI from 'openai';

declare global {
  interface Window {
    // 临时兼容一下过去，后续需要废弃
    openai: OpenAI;
    OpenAI: typeof OpenAI;
    getOpenAIInstance: () => Promise<OpenAI>;
  }
}

window.OpenAI = OpenAI;

let openai: OpenAI;

// 临时兼容一下过去，后续需要废弃
window.openai = new OpenAI({
  baseURL: 'https://openai.api.createany.ai/v1',
  apiKey: 'sk-createany-api-key',
  dangerouslyAllowBrowser: true,
});

window.getOpenAIInstance = async () => {
  if (!openai) {
    // 当下域名是否匹配dev.createany.ai
    // const isDev = window.location.hostname.includes('dev.createany.ai')
    //   || !window.location.hostname.includes('createany.ai');
    // const host = isDev ? 'https://dev.createany.ai' : 'https://createany.ai';
    // const response = await fetch(`${host}/api/auth/set-cookie`, {
    //   method: 'POST',
    // });
  
    // if (response.status === 401) {
    //   window.location.href = `${host}/login?next=${encodeURIComponent(window.location.href)}`;
    //   console.log('redirect to login');
    //   throw new Error('Unauthorized');
    // }

    window.openai = new OpenAI({
      baseURL: 'https://openai.api.createany.ai/v1',
      apiKey: 'sk-createany-api-key',
      dangerouslyAllowBrowser: true,
    });

    openai = window.openai;
  }
  return openai;
};

export default OpenAI;
